import { closeModal, createModal, openModal } from "../modal/Modal";
import RemoveIcon from '../../assets/icons/remove.svg';
import { createProgressBar, progressBarStartAnimation } from "../progress-bar/ProgressBar";

interface ProgressBarModalStartAnimationProps {
  start: number;
  finish: number;
  duration: number;
}

export function createModalProgressBar(id: string) {
  const modalContent = `
  <div class="progress-bar-wrapper">
      <div class="progress-bar-header">
        <p>Progress</p>
        <button class="close-button" type="button">
          ${RemoveIcon}
        </button>
      </div>
      <div class="progress-bar-content">
      </div>
  </div>
  `;

  const modalEl = createModal(id, modalContent, { extraClass: 'search-modal', hideCloseBtn: true,  disableFocus: true });
  const progressBarContent = modalEl.querySelector('.progress-bar-content');
  const closeButton = modalEl.querySelector('.close-button');
  const { progressBarEl, progressBarFillEl, progressBarTextEl } = createProgressBar();
  progressBarContent?.appendChild(progressBarEl);
  progressBarContent?.appendChild(progressBarTextEl);

  if (closeButton) {
    closeButton.addEventListener('click', () => {
      closeModal(id);
    })
  }

  function open() {
    openModal(id);
  }

  function startAnimation({ start, finish, duration }: ProgressBarModalStartAnimationProps) {
    progressBarStartAnimation({
      progressBarFillEl,
      progressBarTextEl,
      start,
      finish,
      duration
    });
  }

  return { modalEl, startAnimation, open };
}
