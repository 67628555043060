import CheckmarkIcon from '../../assets/icons/checkmark.svg';

export function createProgressBar() {
  const progressBarEl = document.createElement('div');
  progressBarEl.classList.add('progress-bar')

  const progressBarFillEl = document.createElement('div');
  progressBarFillEl.classList.add('progress-bar-fill');

  progressBarEl.appendChild(progressBarFillEl);

  const progressBarTextEl = document.createElement('p');
  progressBarTextEl.classList.add('progress-bar-text');

  return { progressBarEl, progressBarFillEl, progressBarTextEl };
}

interface progressBarStartAnimationProps {
  progressBarFillEl: HTMLDivElement;
  progressBarTextEl: HTMLDivElement;
  start: number;
  finish: number;
  duration: number;
  onEnd?: () => void;
}

export function progressBarStartAnimation({
  progressBarFillEl,
  progressBarTextEl,
  start,
  finish,
  duration
}: progressBarStartAnimationProps) {
  progressBarFillEl.style.width = `${start}%`;
  const len = finish - start;
  const interval = 10;
  // let currentInterval = 0;
  // const intervalAdder = duration / interval;
  const adder = interval / duration * len;
  let currentWidth = start;

  const id = setInterval(() => {
    currentWidth += adder;
    progressBarFillEl.style.width = `${currentWidth}%`;
    progressBarFillEl.style.backgroundColor = '#0F77D9';
    progressBarTextEl.innerHTML = `Progress ${Math.round(currentWidth)}%`;

    if (currentWidth >= finish) {
      progressBarFillEl.style.backgroundColor = '#7DE74B';
      progressBarTextEl.innerHTML = `<span>This task is ${Math.round(currentWidth)}% completed</span> ${CheckmarkIcon}`;
      clearInterval(id);
    }
  }, interval);

  // setTimeout(() => {
  //   clearInterval(id);
  // }, duration);

}