import './app.scss';
import 'core-js';
import 'regenerator-runtime/runtime';
import { createModalProgressBar } from './components/modal-progress-bar/ModalProgressBar';
import { getProgressBarData } from './components/utils/services';

window.onload = () => {
  getProgressBarData()
  .then(({
    data: { data: { lightbox }}
  }) => {
    const id = 'modal-progress-bar';
    const modalProgressBar = createModalProgressBar(id);
  
    modalProgressBar.open();
    modalProgressBar.startAnimation(lightbox);
  
    const resetButton = document.querySelector('#reset-button');
    resetButton?.addEventListener('click', () => {
      modalProgressBar.open();
      modalProgressBar.startAnimation(lightbox)
    });
  }).catch(() => {
    alert('Data error');
  })
};

// import 'react-app-polyfill/ie11';
// import 'react-app-polyfill/stable';
// import React from 'react';
// import ReactDOM from 'react-dom/client';
// // import './index.css';
// import App from './App';
// import reportWebVitals from './reportWebVitals';

// const root = ReactDOM.createRoot(
//   document.getElementById('root') as HTMLElement
// );
// root.render(
//   // <React.StrictMode>
//     <App />
//   // </React.StrictMode>
// );

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
