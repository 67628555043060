import MicroModal, { MicroModalConfig } from 'micromodal';
import RemoveIcon from '../../assets/icons/remove.svg';

console.log('RemoveIcon', RemoveIcon);

interface CreateModalConfig {
    size?: 'full' | 'lg' | 'md' | 'sm';
    align?: 'top' | 'middle';
    extraClass?: string;
    hideCloseBtn?: boolean;
    disableFocus?: boolean;
}

interface MicroModalConfigExtended extends MicroModalConfig {
    noBodyClass?: boolean;
}

const defaultCreateModalConfig = { hideCloseBtn: false };

export const createModal = (id: string, content: string, userConfig: CreateModalConfig) => {
    const config = {
        ...defaultCreateModalConfig,
        ...userConfig
    };

    const { hideCloseBtn, extraClass, size = 'md', align = 'middle' } = config;

    const modalEl = document.createElement('div');
    modalEl.classList.add('modal');
    if (extraClass) {
        const extraClassArray = extraClass.split(' ');
        modalEl.classList.add(...extraClassArray);
    }
    modalEl.classList.add(`size-${size}`);
    modalEl.classList.add(`align-${align}`);
    modalEl.setAttribute('id', id);
    modalEl.setAttribute('aria-hidden', 'true');

    const closeBtn = !hideCloseBtn ?
        `<div class="modal-close-btn-wrapper">
            <button type="button" class="modal-close" aria-label="Close modal" data-modal-close>
                ${RemoveIcon}
            </button>
        </div>` : '';

    const modalOverlay = `
        <div class="modal-overlay" tabindex="-1">
            <div class="modal-scroll">
                <div class="modal-container" role="dialog" aria-modal="true" aria-labelledby="${id}">
                    <div class="modal-content">
                        <div class="modal-content-inner">
                            ${content}
                        </div>
                    </div>
                </div>
            </div>
            ${closeBtn}
        </div>
    `;

    modalEl.insertAdjacentHTML('beforeend', modalOverlay);

    if (!hideCloseBtn) {
        const closeBtnEl = modalEl.querySelector('[data-modal-close]') as HTMLButtonElement;
        closeBtnEl?.addEventListener('click', () => {
            closeModal(id);
        });
    }

    document.body.appendChild(modalEl);

    return modalEl;
};

export const openModal = (id: string, config?: MicroModalConfigExtended) => {
    const { noBodyClass = false, ...rest } = config || {};

    MicroModal.show(id, {
        awaitCloseAnimation: true,
        onClose: () => document.body.classList.remove('modal-opened'),
        ...rest
    });

    if (!noBodyClass) {
        document.body.classList.add('modal-opened');
    }
};

export const closeModal = (id: string) => {
    MicroModal.close(id);
};


